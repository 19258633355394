import Handlebars from "handlebars";

export type RenderTemplateUtilProps = {
  template: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templateData: any;
};
export const renderTemplateUtil = ({
  template,
  templateData,
}: RenderTemplateUtilProps): string => {
  const compiledTemplate = Handlebars.compile(template, { noEscape: true });
  return compiledTemplate(templateData);
};
